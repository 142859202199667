import React, { Component } from "react";

import "./css/animate.css";
import { Row, Image, Modal, Button, Col } from "react-bootstrap";

class Marjatila extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      fileName: "",
      directory: "",
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({ show: false, fileName: "", directory: "" });
  }

  handleShow(file, dir) {
    this.setState({ show: true, fileName: file, directory: dir });
  }

  render() {
    let pictures1 = [
      { image: "111c51f4-11f0-4212-a9f6-3b9a9bd0c873.jpg", text: "" },
      {
        image: "10922306_10205795814028562_5881885211957428514_o.jpg",
        text: "",
      },
      { image: "picture1.jpeg", text: "" },
      { image: "picture3.jpeg", text: "" },
    ];

    let pictures2 = [
      {
        image: "13316911_10208511686763683_719187158696043362_o.jpg",
        text: "",
      },
    ];

    var images1 = pictures1.map(function iterator(key) {
      return (
        <Image
          src={require("./img/" + key.image)}
          onClick={() => {
            this.handleShow(key.image, "");
          }}
          thumbnail
        />
      );
    }, this);

    var images2 = pictures2.map(function iterator(key) {
      return (
        <Image
          src={require("./img/" + key.image)}
          onClick={() => {
            this.handleShow(key.image, "");
          }}
          thumbnail
        />
      );
    }, this);

    return (
      <div className="animated fadeIn">
        <Row>
          <Col sm={12} md={12} className="margin-bottom-32">
            <h2 className="margin-bottom-32">Marjatilan esittely</h2>

            <p>
              Marjojen viljelyä jo yli 45 vuoden ajan Kangasniemeläistä Pynnösen
              marjatilaa pyörittää Silja Seppälä (omaa sukua Pynnönen).
              Sukupolvenvaihdos tilalla tehtiin vuonna 2009, jota ennen Siljan
              isä, Kari Pynnönen viljeli peltoja. Ensimmäiset mansikan taimet
              istutettiin vuonna 1977. Vähin erin Siljan isä alkoi monipuolistaa
              marjavalikoimaa, ensin herukoilla ja sitten myöhemmin
              pensasmustikoilla. 2010-luvun taitteessa istutettiin ensimmäiset
              puutarhavadelman taimet. Yhteensä peltopinta-alaa viljelyssä on
              vajaa 9 hehtaaria. Marjatila työllistää kauden aikana noin 30
              kausityöntekijää.
            </p>
            {images1}

            <h5 className="margin-top-32">
              <b>Monipuolista yrittäjyyttä</b>
            </h5>

            <p>
              Marjatilan yrityskuvioiden lisäksi Silja vuokraa ympärivuotisesti
              kahta hirsihuvilaa, villa Jänisniemeä ja villa Jänissaarta.
              Huvilat ovat Siljan isän rakentamia 2000-luvun vaihteessa.
              Syksyisin ja talvisin Silja toimii myös osa-aikaisissa
              palkkatöissä Pieksämäellä koulupsykologina. Siljan puoliso, Mika
              Hyytiäinen, on osallistunut marjatilan pyörittämiseen yhdessä
              Siljan kanssa v. 2022 lähtien.
            </p>

            {images2}

            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              animation={false}
            >
              <Modal.Body>
                {this.state.fileName !== "" ? (
                  <Image
                    className="w-100 h-100"
                    src={require("./img/" +
                      this.state.directory +
                      this.state.fileName)}
                  />
                ) : (
                  ""
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    this.handleClose();
                  }}
                >
                  Sulje
                </Button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Marjatila;
