import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (

        <div id="Footer" lg={12}>
            <div style={{'marginBottom': '16px'}}>
                <span><b>Pynnösen marjatila</b></span><br/>
                <span>Vaimosniementie 800, 51200, Kangasniemi</span><br/>
                <a href="tel:0440652715" style={{color: 'black'}}>puh. 044 065 2715</a><br/>
            </div>
            <img height="67"
                 alt=""
                className="d-inline-block"
                src={require('./img/sirkkamerkki.jpg')}/>

            <Link to="https://tyonantajat.fi/" target="_blank">
                <img height="67" alt="" className="d-inline-block" src={require('./img/mta.png')}/>
            </Link>
        </div>
    );
}
