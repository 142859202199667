import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import Maps from "./Maps";

export default class Yhteystiedot extends Component {


    render() {

        return (
            <div className="animated fadeIn" style={{'height':'100%'}}>
                <Row>
                    <Col sm={12} md={4} className="margin-bottom-32">
                        <h2 className="margin-bottom-32">Yhteystiedot</h2>
                        <h5>Pynnösen marjatila</h5>
                        <span>Silja Seppälä</span><br/>
                        <span>Vaimosniementie 800</span><br/>
                        <span>51200 Kangasniemi</span><br/>
                        <a href="tel:0440652715" style={{color: 'black'}}>044 065 2715</a><br/>
                        <a href="mailto:pynnosenmarjatila@gmail.com" style={{color: 'black'}}>pynnosenmarjatila@gmail.com</a>
                        <br />
                        <br />
                        <a href="https://www.facebook.com/people/Pynn%C3%B6sen-marjatila/61559311469279/">Katso marjatilan Facebook-sivut</a>
                        
                    </Col> 
                    <Col sm={12} md={8}  style={{height: '250px'}}>
                        <Maps style={{minHeight: '400px', height: '400px'}}/>
                    </Col>
                </Row>

            </div>
        );
    }
}
